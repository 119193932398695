<template>
  <TableWidgetStyled>
    <TableV2
      :title="$tc('widgetTypes.DRILLING_TABLE')"
      :headers="headers"
      :rows="rows"
      :sorting.sync="sorting"
      :filtersAvailable="filtersAvailable"
      :filterOptionsSelected="filterOptionsSelected"
      :isLoading="isLoading"
      :searchQuery="searchQuery"
      :enableSearch="true"
      :isMobile="isMobile"
      :enableFooter="false"
      :messages="messages"
      @textFilter="textSearch"
    />
  </TableWidgetStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { TimeframeMixin } from '@common/mixins'
import resizeMixin from '@/mixins/resize'
import localesMixin from '@/mixins/locales'

import { formatDateAccordingToSettings } from '@/utils/time'
import units, { resolveUnitUI } from '@/utils/units'

import { TABLE_SORT_TYPES } from '@/constants/settings'
import TableV2 from '@/components/Atomic/Molecules/TableV2.vue'
import { compact, concat, get, orderBy, partition } from 'lodash'
import fullTimeDurationFormatter from '@/utils/duration'

import ASSET_DRILLED_HOLES_QUERY from '#/graphql/assetDimensions/drilledHoles.gql'

import { useAssetStore } from '@/stores/assets'

export const TableWidgetStyled = styled('div')`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.navFontNormal};
`
export default {
  inject: ['uiSettings'],
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  mixins: [TimeframeMixin, resizeMixin, localesMixin],
  props: {
    widget: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
    },
  },
  components: {
    TableV2,
    TableWidgetStyled,
  },
  data() {
    return {
      isMobile: true,
      searchQuery: '',
      sorting: {
        key: 'start',
        asc: true,
      },
      filterOptionsSelected: [],
      filtersAvailable: [],
    }
  },
  computed: {
    hasDecimalFormatEnabled() {
      return this.widget?.widgetSettings?.hasDecimalFormatEnabled
    },
    isLoading() {
      return this.$apollo.queries.drilledHoles.loading
    },
    headers() {
      const headers = [
        {
          id: `header_cycle`,
          label: this.$t('drillingTable.cycle'),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'cycle',
          size: 'large',
        },
        {
          id: `header_holeID`,
          label: this.$t('drillingTable.holeID'),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'holeID',
          size: 'large',
        },
        {
          id: `header_start`,
          label: this.$t('drillingTable.start'),
          sortType: TABLE_SORT_TYPES.NEW_TO_OLD,
          isSortable: true,
          sortKey: 'start',
          size: 'medium',
        },
        {
          id: `header_drilledDepth`,
          label: this.$t('drillingTable.drilledDepth'),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'drilledDepth',
          size: 'medium',
          unit: resolveUnitUI(this.selectedUIUnitSystem, 'meters'),
        },
        {
          id: `header_drilledLength`,
          label: this.$t('drillingTable.drilledLength'),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'drilledLength',
          size: 'medium',
          unit: resolveUnitUI(this.selectedUIUnitSystem, 'meters'),
        },
        {
          id: `header_drilledDuration`,
          label: this.$t('drillingTable.drilledDuration'),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'drilledDuration',
          size: 'medium',
          unit: resolveUnitUI(this.selectedUIUnitSystem, 'time'),
        },
        {
          id: `header_location`,
          label: this.$t('drillingTable.location'),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'location',
          size: 'medium',
        },
      ]
      return headers
    },
    cyclesSorted() {
      const partitions = partition(this.drilledHoles, x => !!get(x, this.sorting.key, null))
      const sortDirection = this.sorting.key === 'start' ? (this.sorting.asc ? 'desc' : 'asc') : this.sorting.asc ? 'asc' : 'desc'
      return concat(orderBy(partitions[0], [this.sorting.key], [sortDirection]), partitions[1])
    },
    rows() {
      const rows = this.cyclesSorted.map((item, rowIndex) => {
        let cells = [
          {
            id: `${item.asset}_cycle`,
            sortableValue: item.cycle ? item.cycle : '-',
            isMobilePrimarycol: true,
            mobilePrimarycolLabel: item.cycle,
            headerId: 'header_cycle',
          },
          {
            id: `${item.asset}_holeID`,
            sortableValue: item.holeID,
            headerId: `header_holeID`,
          },
          {
            id: `${item.asset}_start`,
            sortableValue: formatDateAccordingToSettings(item.start, this.uiSettings, this.selectedTimezone),
            headerId: `header_start`,
          },
          {
            id: `${item.asset}_drilledDepth`,
            sortableValue:
              item.drilledDepth !== null
                ? units(
                    item.drilledDepth,
                    'm',
                    resolveUnitUI(this.selectedUIUnitSystem, 'meters'),
                    2,
                    false,
                    true,
                    false,
                    this.thousandsSeperator,
                    this.decimalSeperator,
                    true,
                  )
                : '-',
            headerId: `header_drilledDepth`,
          },
          {
            id: `${item.asset}_drilledLength`,
            sortableValue:
              item.drilledLength !== null
                ? units(
                    item.drilledLength,
                    'm',
                    resolveUnitUI(this.selectedUIUnitSystem, 'meters'),
                    2,
                    false,
                    true,
                    false,
                    this.thousandsSeperator,
                    this.decimalSeperator,
                    true,
                  )
                : '-',
            headerId: `header_drilledLength`,
          },
          {
            id: `${item.asset}_drilledDuration`,
            sortableValue:
              item.drilledDuration !== null
                ? !this.hasDecimalFormatEnabled
                  ? fullTimeDurationFormatter(item.drilledDuration)
                  : units(
                      item.drilledDuration,
                      's',
                      resolveUnitUI(this.selectedUIUnitSystem, 'time'),
                      2,
                      false,
                      true,
                      false,
                      this.thousandsSeperator,
                      this.decimalSeperator,
                      true,
                    )
                : '-',
            headerId: `header_drilledDuration`,
          },
          {
            id: `${item.asset}_location`,
            sortableValue: item.location ? item.location : '-',
            headerId: `header_location`,
          },
        ]
        return {
          rowId: `row_${rowIndex}`,
          cells,
        }
      })

      const filteredTable = rows.filter(row => {
        const props = compact(row.cells.map(item => item.sortableValue))
        return props.some(
          prop =>
            !this.searchQuery ||
            (typeof prop === 'string'
              ? prop.toLowerCase().includes(this.searchQuery.toLowerCase())
              : prop.toString(10).includes(this.searchQuery)),
        )
      })
      return filteredTable
    },
    isOneDaySelected() {
      const interval = this.selectedInterval
      if (!interval) {
        return false
      }
      return interval.length('days') <= 1
    },
    messages() {
      let message = ''
      if (this.rows.length === 0) {
        if (!this.assetStore.isSingleAssetView) {
          message = this.$tc('messages.incompatibleDashboardType')
        } else {
          const dimensionName = this.$t('activityTypes.DRILLING')
          message =
            this.searchQuery === ''
              ? this.isOneDaySelected
                ? this.$t('messages.noDimensionData', { dimension: dimensionName })
                : this.$tc('messages.selectOneDay')
              : this.$t('messages.searchNoResults', { query: this.searchQuery })
        }
      }
      return message
    },
    assetId() {
      return this.assetStore.singleSelectedAsset()?.id
    },
  },
  methods: {
    gridItemResized() {
      this.handleResize()
    },
    handleResize() {
      this.$nextTick(() => {
        const size = get(this.$el, 'clientWidth', 96)
        const isMobile = size < 600 && size >= 96
        this.isMobile = isMobile || (this.isPreview && this.isMobilePreview)
      })
    },
    textSearch(query) {
      this.searchQuery = query
    },
  },
  apollo: {
    drilledHoles: {
      query: ASSET_DRILLED_HOLES_QUERY,
      variables() {
        return {
          from_ts: this.selectedTimeframeParam.start,
          till_ts: this.selectedTimeframeParam.end,
          shifts: this.selectedTimeframeParam.shifts,
          assetIds: this.assetId,
        }
      },
      skip() {
        return !this.assetId || !this.selectedInterval || !this.isOneDaySelected
      },
    },
  },
}
</script>
